import React from 'react';
import { graphql } from 'gatsby';
import Img, { FluidObject } from 'gatsby-image';

import Bio from '../components/bio';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Github from '../icons/github';

interface Props {
  data: {
    site: {
      siteMetadata: {
        title: string;
      };
    };
    markdownRemark: {
      id: string;
      excerpt: string;
      html: string;
      fields: {
        editUrl: string;
      };
      frontmatter: {
        date: string;
        description: string;
        featuredImage: {
          childImageSharp: {
            sizes: FluidObject;
          };
        };
        title: string;
      };
    };
  };
  location: {
    pathname: string;
  };
  pageContext: {
    previous: any;
    next: any;
  };
}

const BlogPostTemplate: React.SFC<Props> = ({
  data,
  location,
  pageContext,
}) => {
  const post = data.markdownRemark;
  const siteTitle = data.site.siteMetadata.title;

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <div className="outer">
        <div className="inner">
          <article className="post-full post">
            <section className="post-full-content">
              <header>
                <h1
                  style={{
                    marginTop: 1,
                    marginBottom: 0,
                  }}
                >
                  {post.frontmatter.title}
                </h1>
                <p
                  style={{
                    display: `block`,
                    marginBottom: 20,
                  }}
                >
                  {post.frontmatter.date}
                </p>
                <Img
                  sizes={post.frontmatter.featuredImage.childImageSharp.sizes}
                  style={{ marginBottom: 50 }}
                />
              </header>
              <div
                className="post-content"
                dangerouslySetInnerHTML={{ __html: post.html }}
              />
              <hr
                style={{
                  marginBottom: 1,
                }}
              />
              <div className="github-edit-link">
                <Github />
                <a href={post.fields.editUrl} target="_blank" rel="noopener">
                  Edit This Post on Github
                </a>
              </div>
            </section>
            <footer>
              <Bio />
            </footer>
          </article>
        </div>
      </div>
    </Layout>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      fields {
        editUrl
      }
      excerpt(pruneLength: 160)
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        description
        featuredImage {
          childImageSharp {
            sizes(maxWidth: 630) {
              ...GatsbyImageSharpSizes
            }
          }
        }
        title
      }
      html
      id
    }
    site {
      siteMetadata {
        title
        author
      }
    }
  }
`;
